import { IMAGE } from "../constants";

const initialState = {
  allImageDataByFilename: [],
};

export default function viewerReducer(state = initialState, action) {
  switch (action.type) {
    case IMAGE.SET_ALL_IMAGE_DATA_BY_FILENAME:
      return {
        ...state,
        allImageDataByFilename: action.payload,
      };
    default:
      return state;
  }
}
