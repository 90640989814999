import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Drawer,
  Divider,
  Button,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DashboardWrapper from "../containers/DashboardWrapper";
import { setCurrentPage, getHomeTotalStatsData, getHomeImageData, getSavedSelectedTagDataByEmail, getAllImageDataByFileName, setAllImageDataByFileName
 } from "../actions";
import Period from "../components/Filter/Period";
import FilterApplied from "../components/Filter/FilterApplied"
import Calendar from "../components/Calendar/Calendar";
import PhotoTable from "../components/Table/PhotoTable";
import TagConfig from "../config/TagConfig";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import moment from 'moment-timezone';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  contentGrid: {
    marginTop: theme.spacing(1),
    width: "100%%",
  },
  contentGridSlim: {
    marginTop: theme.spacing(1),
    width: "90%",
  },
  root: {
    marginTop: 32,
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  date: {
    marginBottom: 12,
  },
  filtered: {
    marginTop: 10,
    marginLeft: 16,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 62,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  tagTextWrapper: {
    padding: "16px",
  },
  tagTip: {
    fontWeight: "bold",
    marginBottom: 16,
  },
  tagText: {
    fontSize: "14px",
    marginBottom: 16,
  }
}));

const Home = ({
  setCurrentPage,
  getHomeTotalStatsData,
  getHomeImageData,
  filterDrawerState,
  currentPageNumber,
  periodType,
  getSavedSelectedTagDataByEmail,
  filterStore,
  seasonData,
  allImageDataByFilename,
  getAllImageDataByFileName,
  setAllImageDataByFileName,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [downloadImage, setDownloadImage] = useState([]);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getHomeTotalStatsData();
  }, []);

  useEffect(() => {
    if (seasonData.length > 0) {
      if (filterStore.tag.length > 0) {
        getSavedSelectedTagDataByEmail();
      } else {
        getHomeImageData({
          pageNumber: currentPageNumber,
          sort: "default",
        });
      }
    }
  }, [currentPageNumber, seasonData]);

  useEffect(() => {
    if (periodType === "season") {
      setTagData(TagConfig.seasonTag);
    } else {
      setTagData(TagConfig.missionTagB.concat(TagConfig.missionTagC));
    }
    setDownloadImage([]);
    setAllImageDataByFileName([]);
  }, [periodType]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleImageSelect = (event, value) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // 選択時に配列へ追加
      setDownloadImage((prev) => [...prev, value]);
    } else {
      // 解除時に配列から削除
      setDownloadImage((prev) => prev.filter((url) => url !== value));
    }
  };

  // ステートの変化を監視
  // useEffect(() => {
  //   console.log("Updated selected images:", downloadImage);
  // }, [downloadImage]);

  useEffect(() => {
    if (allImageDataByFilename.length > 0) {
      zipDownload();
    }
  }, [allImageDataByFilename]);

  const zipDownload = async () => {
    const zip = new JSZip();
    const folder = zip.folder("images");

    if (!folder) return;

    // 現在日時を取得してフォーマット
    const currentDateTime = moment.tz('Asia/Tokyo').format('YYYYMMDD-HHmmss');

    // 各画像をフェッチして ZIP に追加
    await Promise.all(
      allImageDataByFilename.map(async (url, index) => {
        const response = await fetch(url);
        const blob = await response.blob();

        // URL からファイル名を抽出
        const fileNameWithExtension = url.split('/').pop(); // 最後の部分を取得
        const fileName = fileNameWithExtension.replace(/\.jpg$/, '');

        folder.file(`${fileName}.jpg`, blob);
      })
    );

    // ZIP を生成してダウンロード
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, `ピクトレ画像-${allImageDataByFilename.length}件-${currentDateTime}.zip`);
  };

  const handleImageDownload = () => {
    getAllImageDataByFileName(downloadImage);
  };

  // const handleImageDownload = async () => {
  //   // 各画像名を元に関連データを取得
  //   await Promise.all(
  //     downloadImage.map(async (url) => {
  //       const fileName = url.split("/").pop().replace(/\.jpg$/, "");
  //       // Redux Action をディスパッチして Saga に処理を渡す
  //       getAllImageDataByFileName(fileName);
  //     })
  //   );
  // };

  return (
    <DashboardWrapper>
      <Grid container spacing={0} direction="row" justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Grid
            container
            justifyContent={"center"}
            className={open
              ? classes.contentGridSlim
              : classes.contentGrid
            }
          >
            <Grid item xs={12} lg={10}>
              <Grid
                container
                direction="row"
                justifyContent={"space-between"}
                className={open
                  ? classes.contentGridSlim
                  : classes.contentGrid
                }
              >
                <Grid item>
                  <Period />
                </Grid>
                <Grid item>
                  <Calendar />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={10} className={classes.filtered} justifyContent={"space-between"}>
              <Grid
                container
                direction="row"
                justifyContent={"space-between"}
                alignItems="flex-end"
                className={open
                  ? classes.contentGridSlim
                  : classes.contentGrid
                }
              >
                <Grid item>
                  <FilterApplied />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    disabled={downloadImage.length === 0 || downloadImage.length > 10}
                    onClick={handleImageDownload}
                  >
                    画像一括ダウンロード
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={10} className={classes.filtered}>
              <PhotoTable
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                handleImageSelect={handleImageSelect}
                downloadImage={downloadImage}
                setDownloadImage={setDownloadImage}
                open={open}
              />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <div className={classes.tagTextWrapper}>
          {tagData.length > 0 &&
            <>
              <div className={classes.tagTip}>
                {periodType === "season" ? "シーズン" : "ミッション"}結果タグ説明
              </div>
              {tagData.length > 0 && tagData.map((row, index) => (
                <div className={classes.tagText} key={index}>{row["tip"]}</div>
              ))}
            </>
          }
        </div>
      </Drawer>
    </DashboardWrapper>
  );
};

Home.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  filterDrawerState: state.app.filterDrawerState,
  currentPageNumber: state.home.currentPageNumber,
  periodType: state.app.periodType,
  filterStore: state.home.filter,
  seasonData: state.app.seasonData,
  allImageDataByFilename: state.image.allImageDataByFilename,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getHomeTotalStatsData: () => dispatch(getHomeTotalStatsData()),
  getHomeImageData: (param) => dispatch(getHomeImageData(param)),
  getSavedSelectedTagDataByEmail: () => dispatch(getSavedSelectedTagDataByEmail()),
  getAllImageDataByFileName: (param) => dispatch(getAllImageDataByFileName(param)),
  setAllImageDataByFileName: (param) => dispatch(setAllImageDataByFileName(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
