import { all } from 'redux-saga/effects';

import appSaga from './appSaga';
import homeSaga from './homeSaga';
import viewerSaga from './viewerSaga';
import imageSaga from './imageSaga';

export default function* rootSaga() {
    yield all([
        appSaga(), homeSaga(), viewerSaga(), imageSaga(),
    ]);
}
