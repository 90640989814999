import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as Var from "../../styles/variables";
import { setPeriodType } from "../../actions";
import * as H from "../../utils/helper";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: 12,
      color: "#484848",
    },
    filterTitle: {
      fontSize: 14,
      color: "#484848",
    },
    dataBtn: {
      marginTop: theme.spacing(1.2),
      marginLeft: theme.spacing(1),
      height: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
      color: Var.btnLabelColor,
    },
    filterItem: {
      marginTop: theme.spacing(1),
    },
  })
);

const getAssetTypeName = (assetType) => {
  switch(assetType) {
    case "POWER_POLE":
      return "電柱（全て）";
    case "POWER_POLE_HAS_PROTECTIVE_TUBE_ALL":
      return "電柱（防護管付き・全て電柱（防護管付き・全て）";
    case "POWER_POLE_HAS_PROTECTIVE_TUBE_WITH_PIC":
      return "電柱（防護管付き・写真あり）";
    case "MAN_HOLE":
      return "マンホール";
    case "HAND_HOLE":
      return "ハンドホール";
    case "GRATING":
      return "換気口";
    case "GROUND_EQUIPMENT-MS":
      return "地上設備 MS";
    case "GROUND_EQUIPMENT-PM":
      return "地上設備 PM";
    default:
      break;
  }
}

const FilterApplied = ({
  setRange,
  dateRange,
  periodType,
  setPeriodType,
  selectedSeason,
  filteredTotal,
  filterStore,
}) => {
  const classes = useStyles();
  const [assetTypeSel, setAssetTypeSel] = useState("全て");
  const signNumberValue = filterStore.signNumber === null ? "全て" : filterStore.signNumber;
  const assetValue = filterStore.assetId === null ? "全て" : filterStore.assetId;
  const assetTypeValue = filterStore.assetType === "all" && filterStore.assetType === "MISSION_ASSET"
    ? "全て" : getAssetTypeName(filterStore.assetType);
  const sortValue = filterStore.type === "occurred_datetime" && filterStore.order === "desc"
    ? "撮影日時 - 降順" : "撮影日時 - 昇順";

  useEffect(() => {
    const asv = filterStore.assetType === "all" || filterStore.assetType === "MISSION_ASSET"
    ? "全て" : getAssetTypeName(filterStore.assetType);
    setAssetTypeSel(asv);
  }, [filterStore.assetType]);

  return (
    <>
      <div className={classes.title}>適用絞り込み条件</div>
      <Grid
        container
        direction="column"
        justifyContent={"space-between"}
        className={classes.filtered}
      >
        <Grid item className={classes.filterItem}>
         <span className={classes.filterTitle}>標識番号</span>： {signNumberValue}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
         <span className={classes.filterTitle}>設備番号</span>： {assetValue}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
         <span className={classes.filterTitle}>アセットタイプ</span>： {assetTypeSel}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
         <span className={classes.filterTitle}>ソート</span>： {sortValue}
        </Grid>
        <Grid item className={classes.filterItem}>
          <span className={classes.filterTitle}>件数</span>：{H.FormatNumber.formatCommaThousand(filteredTotal)}
        </Grid>
      </Grid>
    </>
  );
};

FilterApplied.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dateRange: state.app.dateRange,
  periodType: state.app.periodType,
  selectedSeason: state.app.selectedSeason,
  filteredTotal: state.home.filteredTotal,
  filterStore: state.home.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setPeriodType: (param) => dispatch(setPeriodType(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(FilterApplied));
