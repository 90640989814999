import { put, call, all, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { VIEWER, ENDPOINT } from '../constants';

import {
  setPageTopLoader,
  setImageDataByFileName,
  setViewerImageData,
} from '../actions';

import {
  getJwtToken,
  getDataFromSxi,
  getDataFromKpiDash,
} from '../api';

const S3_BUCKET_BASE_URL = process.env.REACT_APP_S3_BUCKET_BASE_URL;

export const startDate = state => state.app.dateRange[0];
export const endDate = state => state.app.dateRange[1];

export function* handleImageDataByFileName(actions) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const fileName = `${actions.payload}.jpg`;

    // Query step1: Get review_request_id by a param file name
    const sqlQuery1 = {
      query: `
        SELECT
            ppa.*,
            gs.*,
            gt.*,
            pae.occurred_datetime AS occurred_datetime,
            CONVERT_TZ(pae.occurred_datetime, '+00:00', '+09:00') AS occurred_datetime_jst,
            ps.*,
            padtep.*,
            rrpb.review_request_id
        FROM
            power_asset_event pae
        JOIN
            photo_of_power_asset ppa ON ppa.power_asset_id = pae.power_asset_id
            AND ppa.player_id = pae.player_id
            AND ppa.game_space_id = pae.game_space_id
        JOIN
            game_space gs ON gs.id = pae.game_space_id
        JOIN
            game_term gt ON gt.id = gs.game_term_id
        JOIN
            player_state ps ON pae.player_id = ps.player_id
        JOIN
            power_asset_detail_of_tepco padtep ON pae.power_asset_id = padtep.power_asset_id
        JOIN review_request_photo_bind rrpb ON rrpb.photo_of_power_asset_id = ppa.id
        WHERE
            pae.type = 'PHOTOGRAPH'
            AND ppa.file_name = '${fileName}'
        GROUP BY
            pae.power_asset_id, ps.player_id, occurred_datetime
        ORDER BY
            pae.occurred_datetime DESC
        LIMIT 1
      `
    }
    const [imageReviewDataTemp] = yield all([
      call(getDataFromKpiDash, sqlQuery1, ENDPOINT.GET_DATA_API),
    ]);
    const imageReviewData = JSON.parse(imageReviewDataTemp.body);
    // console.log("LOG1 imageReviewData:", imageReviewData);

    // Query step2: based on fileName > asset_id, get the all other photo S3 object key
    const sqlQuery2 = {
      query: `
        SELECT
          *,
          DATE_FORMAT(CONVERT_TZ(rre.occurred_datetime, '+00:00', '+09:00'), '%Y-%m-%d %H:%i:%s') AS occurred_datetime_jst,
          gt.type AS game_space_type
        FROM review_request_photo_bind rrpb
        JOIN photo_of_power_asset ppa ON rrpb.photo_of_power_asset_id = ppa.id
        JOIN game_space gs ON gs.id = ppa.game_space_id
        JOIN game_term gt ON gt.id = gs.game_term_id
        JOIN power_asset_detail_of_tepco padtep ON ppa.power_asset_id	 = padtep.power_asset_id
        JOIN player_state ps ON ps.player_id = ppa.player_id
        JOIN review_request_event rre ON rrpb.review_request_id = rre.review_request_id
        WHERE rrpb.review_request_id = '${imageReviewData[0]["review_request_id"]}'
        AND rre.type = 'REGISTERED';
      `
    }
    const [imageFilesDataTemp] = yield all([
      call(getDataFromKpiDash, sqlQuery2, ENDPOINT.GET_DATA_API),
    ]);
    let imageFilesData = JSON.parse(imageFilesDataTemp.body);
    // console.log("LOG2 :", imageFilesData);

    // Query step3: Get all signedUrls
    let photoPathArray = [], photoFileNameArray = [];
    _.each(imageFilesData, function(photoData) {
      photoPathArray.push(photoData.object_key);
      photoFileNameArray.push(photoData.file_name);
    });

    // Deprecated: S3 bucket access is set public @2024/12/23
    // const signedUrlCalls = photoPathArray.map((path, index) => {
    //   return call(getDataFromSxi, {
    //     filePath: path,
    //     fileName: photoFileNameArray[index]
    //   }, ENDPOINT.GET_DATA_SXI_PHOTO_BY_FILE, jwtToken);
    // });

    // const signedUrlResponses = yield all(signedUrlCalls);

    // const photoSignedUrls = signedUrlResponses.map(response => {
    //   return JSON.parse(response.body).signedUrl;
    // });

    _.each(imageFilesData, function(photoData, index) {
      photoData.signedUrl = `${S3_BUCKET_BASE_URL}/${photoPathArray[index]}`; //photoSignedUrls[index];
    });

    // update 0 index data from review request dateTime to photo sent dateTime
    imageFilesData[0].occurred_datetime_jst = imageReviewData[0]["occurred_datetime_jst"].slice(0, 19).replace("T", " ");
    yield put(setViewerImageData(imageFilesData));
    yield put(setPageTopLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export default function* watchMapSettings() {
  yield takeEvery(VIEWER.GET_IMAGE_DATA_BY_FILENAME, handleImageDataByFileName);
}